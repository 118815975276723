
<template>
<div class="container mt-4">
    <br/>
    <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8">
            <form class="card card-sm">
                <div class="card-body row no-gutters align-items-center">
                    <div class="col-auto">
                        <i class="fas fa-search h4 text-body"></i>
                    </div>
                    <!--end of col-->
                    <div class="col">
                        <!-- <input  type="search" placeholder="Buscar modelo"> -->
                        <vue-bootstrap-typeahead 
                            inputClass="form-control form-control-lg form-control-borderless" 
                            v-model="search" 
                            :serializer="d => d.query"
                            :data="devices"
                            placeholder="Buscar modelo..."
                            @hit="device = $event"
                            />
                    </div>
                    <!--end of col-->
                    <div class="col-auto">
                        <button class="btn btn-lg btn-success" type="button">Buscar</button>
                    </div>
                    <!--end of col-->
                </div>
            </form>
        </div>
        <!--end of col-->
    </div>
</div>
</template>


<style type="text/css" media="screen">

.form-control-borderless {
    border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
    
</style>

<script>

    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

    const API_URL = 'http://localhost:8080/api/search?query=:query'

    export default {

        components: {
            VueBootstrapTypeahead
        },

        data() {
            return {
                search : '',
                device : null,
                devices: [],
                text: 'Buscar',
            }
        },

        methods:{
            async getSearch(query) {
                const res = await fetch(API_URL.replace(':query', query))
                const response = await res.json()
                this.devices = response
            },
            loadData: function() {
                let _self = this
                axios.get('/api/devices').then(response => {
                if(response.status == 200){
                    this.devices = response.data;
                }
                }).catch(error => {
                    alert("Sucedio un error")
                });
            },
            clickSearch: function () {
                console.log('hello')
            }
        },

        watch: {
            // search: _.debounce(function(addr) { this.getSearch(addr) }, 500),
            device: function(value) {
                // enviar a pagina
                window.location.href = '/dispositivo/' + value.slug
            }
        },

        mounted() {
            this.loadData();
        }
    }
</script>